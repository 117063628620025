<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
        <b-col>
          <h6 class="brand-text text-primary ml-1 text-uppercase mt-1">
            County Government of
          </h6>
          <h4 class="brand-text text-primary ml-1 text-uppercase">Kericho</h4>
        </b-col>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Kericho County" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-sm-5"
      >
        <b-col sm="8" md="6" lg="12" class="mt-2 px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to Kericho CPSB! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Complete the form below to register for an account
          </b-card-text>

          <!-- form -->
          <validation-observer ref="registerForm">
            <b-form class="auth-register-form mt-2">
              <b-form-group label="First Name" label-for="given_name">
                <validation-provider
                  #default="{ errors }"
                  name="First Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="given_name"
                    v-model="given_name"
                    name="given_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="John"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Middle Name (Optional)"
                label-for="middle_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Middle Name"
                  rules="alpha"
                >
                  <b-form-input
                    id="middle_name"
                    v-model="middle_name"
                    name="middle_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Doe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Last Name" label-for="family_name">
                <validation-provider
                  #default="{ errors }"
                  name="Last Name"
                  rules="required|min:3"
                >
                  <b-form-input
                    id="family_name"
                    v-model="family_name"
                    name="family_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Smith"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Username" label-for="register-username">
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required|alpha-dash|min:3"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false : null"
                    placeholder="johndoe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email Address" label-for="email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="userEmail"
                    name="email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Phone Number" label-for="phone_number">
                <validation-provider
                  #default="{ errors }"
                  name="Phone Number"
                  rules="required|integer|length:10|regex:^([0-9]+)$"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="phone_number"
                    name="phone_number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="0xxxxxxxxx"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group label-for="password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row class="mx-1 mb-2 d-flex justify-content-center">
                <b-form-radio
                  v-model="gender"
                  class="mx-1"
                  name="gender"
                  value="Male"
                >
                  Male
                </b-form-radio>
                <b-form-radio
                  v-model="gender"
                  class="mx-1"
                  name="gender"
                  value="Female"
                >
                  Female
                </b-form-radio>
                <b-form-radio
                  v-model="gender"
                  class="mx-1"
                  name="gender"
                  value="Other"
                >
                  Other
                </b-form-radio>
              </b-row>

              <!-- <b-form-group>
                <b-form-checkbox
                  id="privacy_policy"
                  v-model="privacy_policy"
                  name="privacy_policy"
                >
                  I agree to
                  <b-link>privacy policy & terms</b-link>
                </b-form-checkbox>
              </b-form-group> -->

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="!formValid"
                @click.prevent="validationForm"
              >
                Register
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;Login instead</span>
            </b-link>
          </p>

          <visitors-badge :minimal="false" />
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable operator-linebreak */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormRadio,
} from 'bootstrap-vue'
// eslint-disable-next-line object-curly-newline
import { required, email, integer, length, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import VisitorsBadge from '@/components/stats/VisitorsBadge.vue'

export default {
  components: {
    Logo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormRadio,
    // validations
    ValidationProvider,
    ValidationObserver,
    VisitorsBadge,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      privacy_policy: '',
      given_name: '',
      middle_name: '',
      family_name: '',
      phone_number: '',
      gender: '',
      username: '',
      userEmail: '',
      password: '',

      sideImg: require('@/assets/images/flags/kcg-logo.png'),
      // validation
      required,
      email,
      integer,
      length,
      min,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    formValid() {
      return (
        this.given_name !== '' &&
        this.family_name !== '' &&
        this.phone_number !== '' &&
        this.gender !== '' &&
        this.username !== '' &&
        this.userEmail !== '' &&
        this.password !== ''
      )
    },
  },
  methods: {
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    validationForm() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          await this.$store
            .dispatch('auth/registerUser', {
              given_name: this.given_name.trim(),
              middle_name: this.middle_name.trim(),
              family_name: this.family_name.trim(),
              preferred_username: this.username.trim(),
              email: this.userEmail.trim(),
              password: this.password.trim(),
              phone_number: this.phone_number.trim(),
              gender: this.gender,
            })
            .then(() => {
              this.showToast(
                'Success',
                'Registration successfully completed. Please login.',
                'Check',
                'success',
                // eslint-disable-next-line comma-dangle
                15000,
              )
              this.$router.push({ name: 'login' }).catch(
                err =>
                  // eslint-disable-next-line implicit-arrow-linebreak
                  this.showToast(
                    'Navigation Error',
                    err.message,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                    // eslint-disable-next-line comma-dangle
                  ),
                // eslint-disable-next-line function-paren-newline
              )
            })
            .catch(err => {
              if (err.response) {
                if ('data' in err.response) {
                  if ('data' in err.response.data) {
                    if (Array.isArray(err.response.data.data)) {
                      err.response.data.data.forEach(param => {
                        this.showToast(
                          `Error ${err.response.status}`,
                          param.message,
                          'X',
                          'danger',
                          // eslint-disable-next-line comma-dangle
                          15000,
                        )
                      })
                    }
                  } else {
                    this.showToast(
                      `Error ${err.response.status}`,
                      `${err.response.data.message}`,
                      'X',
                      'danger',
                      // eslint-disable-next-line comma-dangle
                      15000,
                    )
                  }
                } else {
                  this.showToast(
                    `Error ${err.response.status}`,
                    `${err.response.statusText}`,
                    'X',
                    'danger',
                    // eslint-disable-next-line comma-dangle
                    15000,
                  )
                  // eslint-disable-next-line function-paren-newline
                }
              } else {
                this.showToast(
                  'Error',
                  `${err.message}`,
                  'X',
                  'danger',
                  // eslint-disable-next-line comma-dangle
                  15000,
                )
                // eslint-disable-next-line function-paren-newline
              }
            })
        } else {
          this.showToast(
            'There seems to be a problem in your submission',
            undefined,
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
