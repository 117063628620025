var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('logo'),_c('b-col',[_c('h6',{staticClass:"brand-text text-primary ml-1 text-uppercase mt-1"},[_vm._v(" County Government of ")]),_c('h4',{staticClass:"brand-text text-primary ml-1 text-uppercase"},[_vm._v("Kericho")])])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"6"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Kericho County"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5 pt-sm-5",attrs:{"lg":"6"}},[_c('b-col',{staticClass:"mt-2 px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to Kericho CPSB! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Complete the form below to register for an account ")]),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2"},[_c('b-form-group',{attrs:{"label":"First Name","label-for":"given_name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"given_name","name":"given_name","state":errors.length > 0 ? false : null,"placeholder":"John"},model:{value:(_vm.given_name),callback:function ($$v) {_vm.given_name=$$v},expression:"given_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Middle Name (Optional)","label-for":"middle_name"}},[_c('validation-provider',{attrs:{"name":"Middle Name","rules":"alpha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"middle_name","name":"middle_name","state":errors.length > 0 ? false : null,"placeholder":"Doe"},model:{value:(_vm.middle_name),callback:function ($$v) {_vm.middle_name=$$v},expression:"middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"family_name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"family_name","name":"family_name","state":errors.length > 0 ? false : null,"placeholder":"Smith"},model:{value:(_vm.family_name),callback:function ($$v) {_vm.family_name=$$v},expression:"family_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Username","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|alpha-dash|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","name":"register-username","state":errors.length > 0 ? false : null,"placeholder":"johndoe"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email Address","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","name":"email","state":errors.length > 0 ? false : null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|integer|length:10|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone_number","name":"phone_number","state":errors.length > 0 ? false : null,"placeholder":"0xxxxxxxxx"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',{staticClass:"mx-1 mb-2 d-flex justify-content-center"},[_c('b-form-radio',{staticClass:"mx-1",attrs:{"name":"gender","value":"Male"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Male ")]),_c('b-form-radio',{staticClass:"mx-1",attrs:{"name":"gender","value":"Female"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Female ")]),_c('b-form-radio',{staticClass:"mx-1",attrs:{"name":"gender","value":"Other"},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_vm._v(" Other ")])],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":!_vm.formValid},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Register ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',[_vm._v(" Login instead")])])],1),_c('visitors-badge',{attrs:{"minimal":false}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }